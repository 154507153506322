<template>
  <div class="pageContol classReviewDetail">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置： </em>
          <a href="javascript:;" @click="$router.back()">培训任务列表</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">课程列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课程详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="ovy-a">
          <h3 style="padding-bottom: 20px">课程基本信息</h3>
          <div>
            <el-form :inline="true" ref="ruleForm" label-width="10rem">
              <el-form-item label="课程名称：" class="form-inline">
                <p>{{ ruleForm.courseName }}</p>
              </el-form-item>
              <el-form-item
                v-show="ruleForm.trainTypeNamePath"
                label="培训类型："
                class="form-inline"
              >
                <p>{{ ruleForm.trainTypeNamePath || "" }}</p>
              </el-form-item>
            </el-form>
            <el-form ref="ruleForm" label-width="10rem">
              <el-form-item label="课程详情：" prop="context" class="form-item">
                <div
                  v-html="ruleForm.context"
                  ref="editor"
                  style="width: 100%; position: relative; z-index: 2"
                />
              </el-form-item>
              <el-form-item label="课程图片：" prop="thumbnail">
                <div class="img-el-upload" style="width: 256px">
                  <el-image
                    :src="ruleForm.thumbnail || require('@/assets/develop.png')"
                    fit="contain"
                  ></el-image>
                </div>
              </el-form-item>
              <el-form-item
                label="视频累计时长："
                class="form-inline"
              >
                <p>{{ getTime(duration) || ruleForm.durationStr }}</p>
              </el-form-item>
            </el-form>
          </div>
          <h3 style="padding: 20px 0">课程章节</h3>
          <el-checkbox v-if="ruleForm.projectAuditState!='30'" v-model="wocao" @change="selectChange"  style="margin-left: 24px;">全选</el-checkbox>
          <el-tree
            ref="tree"
            :data="data"
            :show-checkbox="ruleForm.projectAuditState!='30'"
            node-key="courseKpointId"
            default-expand-all
            :expand-on-click-node="false"
            :default-checked-keys="this.selects"
            :props="defaultProps"
            @check-change="getChecked"
          >
            <span class="custom-tree-node" slot-scope="{ data }">
              <template v-if="data.level == 1">
                <span style="width: 44px; text-align: left">
                  <span class="level" style="margin-right: 10px">
                    <!-- 一级 -->
                    {{ data.chapterNum }}
                  </span>
                </span>
                <p :title="data.catalogName" class="ellipsis">
                  {{ data.catalogName }}
                </p>
              </template>
              <template v-else-if="data.level == 2">
                <!-- <div> -->
                <span style="width: 44px; text-align: left">
                  <span class="level" style="margin-right: 10px">
                    {{ data.chapterNum }}
                  </span>
                </span>
                <span
                  v-if="data.list.length > 0"
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <i class="courseIcon" v-if="data.list[0].kpointId"></i>
                  <i class="paperIcon" v-else></i>
                </span>
                <span
                  v-text="data.catalogName"
                  class="ellipsis"
                  style="width: 33.5rem"
                  :title="data.catalogName"
                ></span>
                <span>{{ data.kpointDurationStr }}</span>
                <el-button
                  type="text"
                  @click="showVideo(data)"
                  size="mini"
                  >预览</el-button
                >
              </template>
            </span>
          </el-tree>
          <div
            style="
              display: flex;
              justify-content: center;
              left: 50%;
              right: 50%;
            "
          >
            <el-button @click="$router.back()" class="bgc-bv">取消</el-button>
            <el-button
              @click="getCheckedNodes"
              v-if="ruleForm.projectAuditState!='30'"
              class="bgc-bv"
              >确定</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="dialogVisible1"
      :title="videotitle"
      :visible.sync="dialogVisible1"
      width="60%"
      :before-close="handleClose"
    >
      <player_2
        :videoId="videoId"
        :courseId="courseId"
        :kpointId="kpointId"
        :videoTime="videoTime"
        :kpointSource="kpointSource"
        v-if="videoType"
        ref="palyer"
      />
      <player_1
        :videoId="videoId"
        :courseId="courseId"
        :kpointId="kpointId"
        :videoTime="videoTime"
        :kpointSource="kpointSource"
        v-else
        ref="palyer"
      />
    </el-dialog>
    <PaperShow ref="PaperShow" />
  </div>
</template>
<script>
import { console } from "../../../utils/helper";

let id = 1000;
import List from "@/mixins/List";
import PaperShow from "@/views/resourse/popup/PaperShow";
import player_1 from "@/components/player";
import player_2 from "@/components/playerTrysee";
import { createSwigger, distorySwigger } from "../../../utils/swigger";
import { duration } from "moment";
export default {
  name: "seeClassMinsCtr",
  components: {
    PaperShow,
    player_1,
    player_2,
  },
  mixins: [List],
  data() {
    return {
      wocao:true,
      data: [],
      defaultProps: {
        id: "courseKpointId",
        children: "children",
        label: "courseKpointId",
      },
      ruleForm: {
        createId: "",
        courseName: "", // 课程名称
        context: "", //课程详情
        thumbnail: "",
        trainTypeNamePath: "", // 培训类型名称树
        postName: "", // 岗位名称
        industrynamePath: "", // 行业名称树
        lecturer: "",
      },
      selects: [],
      projectId: "",
      courseId: "",
      videoId: "", // 視頻id
      videoType: null, // true-可以看；false-试看
      dialogVisible1: false,
      videotitle: "",
      videoTime: "",
      status: 1,
      editBtn: true, //
      duration: 0,
    };
  },
  created() {
    this.courseId = this.$route.query.courseId;
    this.projectId = this.$route.query.projectId;
    this.getData();
    this.$nextTick(() => {
      this.getDetail();
    });
  },

  mounted() {},
  methods: {
    handleClose() {
      (this.dialogVisible1 = false), (this.status = 3);
      this.$refs.palyer.dd();
      distorySwigger();
    },
    getDetail() {
      this.$post(
        "/biz/et/trainTask/course/configureProjectCourseInfo",
        {
          projectCourseId: this.$route.query.projectCourseId,
        }
      ).then((result) => {
        const data = result.data || {};
        if (result.status === "0") {
          this.ruleForm = {
            projectCourseId: data.projectCourseBo.projectCourseId,
            courseName: data.editDisplayBO.courseName, // 课程名称
            context: data.editDisplayBO.context, //课程详情
            thumbnail: data.editDisplayBO.logoURL,
            lecturer: data.editDisplayBO.lecturer,
            trainTypeNamePath: data.editDisplayBO.trainTypeNamePath,
            postName: data.editDisplayBO.postName,
            industryNamePath: data.editDisplayBO.industryNamePath,
            occupationNamePath: data.editDisplayBO.occupationNamePath,
            trainLevelName: data.editDisplayBO.trainLevelName,
            projectAuditState: data.projectAuditState
          };
          //   for (let index = 0; index < data.selects.length; index++) {
          //     console.log(data.selects[index]);
          //   if(data.editDisplayBO.nodes[index].courseKpointId == data.selects[index]) {
          //     console.log('2222');
          //     console.log(data.editDisplayBO.nodes[index].kpointDuration);
          //     this.duration  += data.editDisplayBO.nodes[index].kpointDuration
          //       console.log(this.duration);
          //   }

          // }
          this.selects = data.selects.map(Number);
          let duration = 0;
          for (let i = 0; i < data.editDisplayBO.nodes.length; i++) {
            if (!data.editDisplayBO.nodes[i].child) {
              if (
                data.selects.indexOf(
                  data.editDisplayBO.nodes[i].courseKpointId
                ) > -1
              ) {
                duration += data.editDisplayBO.nodes[i].kpointDuration;
              }
            } else {
              for (
                let j = 0;
                j < data.editDisplayBO.nodes[i].child.length;
                j++
              ) {
                if (
                  data.selects.indexOf(
                    data.editDisplayBO.nodes[i].child[j].courseKpointId
                  ) > -1
                ) {
                  duration +=
                    data.editDisplayBO.nodes[i].child[j].kpointDuration;
                }
              }
            }
          }
          this.duration = duration;
          this.creatData(data.editDisplayBO);
        }
      });
    },
    creatData(data = {}) {
      let list = [];
      this.chapterSchema = data.chapterSchema;
      if (data.chapterSchema) {
        // 样式一 ------> 三层
        data.nodes.forEach((element) => {
          let nodeList = [];
          list.push({
            courseKpointId: element.courseKpointId,
            catalogName: element.catalogName,
            parentNodeId: element.parentNodeId,
            children: nodeList,
            level: 1,
          });
          (element.child || []).forEach((obj) => {
            nodeList.push(this.created2dom(obj));
          });
        });
      } else {
        data.nodes.forEach((element) => {
          list.push(this.created2dom(element));
        });
      }
      this.data = list;
      this.mapData(list);
    },
    created2dom(obj) {
      let children = [];
      let listArr = [];
      if (obj.kpointVideoId) {
        obj.list = listArr;
        listArr.push({
          kpointVideoId: obj.kpointVideoId,
          kpointId: obj.kpointId,
          kpointSource: obj.kpointSource,
        });
      } else if (obj.paperId) {
        obj.list = listArr;
        listArr.push({
          paperId: obj.paperId,
          paperName: obj.paperName,
          kpointSource: obj.kpointSource,
        });
      }
      //判断kpointId存在，paperId存在，带试卷的课件，kpointId不存在，paperId存在是单元测试
      if (obj.kpointId) {
        return {
          catalogName: obj.catalogName,
          parentNodeId: obj.parentNodeId,
          kpointLessonNum: obj.kpointLessonNum,
          level: 2,
          list: listArr,
          children: children,
          requiredState: obj.requiredState,
          section: obj.section,
          courseKpointId: obj.courseKpointId,
          sourceCourseId: this.courseId,
          sourceCourseKpointId: obj.courseKpointId,
          kpointId: obj.kpointId,
          requiredState: obj.requiredState,
          kpointDuration: obj.kpointDuration,
          kpointDurationStr: obj.kpointDurationStr,
        };
      } else {
        return {
          catalogName: obj.catalogName,
          parentNodeId: obj.parentNodeId,
          kpointLessonNum: obj.kpointLessonNum,
          level: 2,
          list: listArr,
          children: [],
          requiredState: obj.requiredState,
          section: obj.section,
          courseKpointId: obj.courseKpointId,
          sourceCourseId: this.courseId,
          sourceCourseKpointId: obj.courseKpointId,
          kpointId: obj.kpointId,
          paperId: obj.paperId,
          paperName: obj.paperName,
          kpointDuration: obj.kpointDuration,
          kpointDurationStr: obj.kpointDurationStr,
        };
      }
    },
    getChecked(data, checked, indeterminate) {
      let num = 0;
      let ElectiveNum = 0;
      let duration = 0;
      this.$refs.tree.getCheckedNodes().map((item, index) => {
        if (item.level == 2) {
          if (item.requiredState == true) {
            if (item.kpointLessonNum) {
              num += item.kpointLessonNum;
            }
          } else {
            let kpointLessonNum =
              item.kpointLessonNum && item.kpointLessonNum != undefined
                ? item.kpointLessonNum
                : 0;
            ElectiveNum += kpointLessonNum;
          }
          duration += item.kpointDuration;
        }
      });

      this.duration = duration;
    },
    showPaper(data) {
      this.$refs.PaperShow.showPopUp(data.paperId); // 打开弹窗预览试题
    },
    // 全选
    selectChange(type) {
      let res = this.$refs.tree;
      let nodes = res.getCheckedNodes(true, true);
      this.batchSelect(this.data, res, type, nodes);  
    },
    batchSelect(nodes, refs, flag, selecteds) {
      if (typeof nodes != "undefined") {
        nodes.forEach((element) => {
          refs.setChecked(element, flag, true);
        });
      }
      if (typeof selecteds != "undefined") {
        selecteds.forEach((node) => {
          refs.setChecked(node, flag, true);
        });
      }
    },
    /**
     * 保存
     */
    getCheckedNodes() {
      let checked = [];
      checked = this.$refs.tree
        .getCheckedKeys()
        .concat(this.$refs.tree.getHalfCheckedKeys());
      if (checked.length == 0) {
        this.$message({
          message: "请选择课程编排",
          type: "warning",
        });
        return false;
      }
      let parmar = {
        courseId: this.$route.query.courseId,
        modify: 1,
        projectId: this.projectId,
        selectIds: checked,
        selectNode: this.data,
        projectCourseId: this.$route.query.projectCourseId,
      };
      this.$post("/biz/et/trainTask/course/configureProjectCourse", parmar)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.$router.back();
          }
        })
        .catch((err) => {
          return;
        });
    },
    isInteger(obj) {
      return Math.floor(obj) == obj;
    },
    // 预览
    showVideo(data) {
      console.log(data);
      let _this = this;
      //单元测试打开是题库
      if (data.list[0].kpointVideoId) {
        _this
          .$post("/biz/courseware/auth", {
            videoId: data.list[0].kpointVideoId,
          })
          .then((result) => {
            _this.videoId = data.list[0].kpointVideoId;
            _this.videoType = result.data.trySee;
            _this.videoTime = result.data.duration;
            _this.videotitle = data.catalogName;
            _this.courseId = _this.courseId;
            _this.kpointId = data.kpointId;
            _this.kpointSource = data.list[0].kpointSource;
            _this.dialogVisible1 = true;
          });
      } else {
        this.showPaper(data);
      }
    },
    mapData(data) {
      //样式1下
      data.forEach((item, i) => {
        item["chapterNum"] = Number(i) + 1;
        item.children.forEach((el, k) => {
          el["chapterNum"] = Number(i) + 1 + "-" + (Number(k) + 1);
        });
      });

      this.$nextTick(() => {
        this.data = data;
      });
    },
    getTime(val) {


      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
};
</script>
<style lang="less">
.imgbox {
  .el-form-item__content {
    width: 256px !important;
    height: 156px;
  }
}
.form-inline {
  width: 45%;
  p {
    width: 20rem;
  }
}
.classReviewDetail {
  .lessNumcount {
    .el-icon-arrow-down:before {
      content: "\e6df" !important;
    }
  }
}
.el-image {
  display: block;
}

.imgBoxs {
  display: flex;
  > img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    z-index: 1;
  }
}
.editLecture {
  .el-form-item__content {
    display: flex;
    align-items: center;
  }
}
</style>
<style lang="less" scoped>
.Basicinformation {
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 50%;
    padding: 10px 0;
  }
  .information {
    display: flex;
    flex-wrap: wrap;
    .information-item {
      width: 50%;
    }
  }
}
.lessonNum {
  display: flex;
  padding: 1rem;
  span {
    padding: 0 1rem;
  }
}
.lessNumcount {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  span {
    width: 10rem;
  }
}
.custom-tree-node {
  display: flex;
  align-items: center;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 40rem;
  font-size: 14px;
}
.ellipsiss {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 40rem;
  font-size: 14px;
}
.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-tree-node {
  .el-form-item {
    margin-bottom: 0px;
  }
}
.qualificationImageId {
  .el-image {
    display: flex;
    height: 500px;
  }
}
.dataqualificationImageId {
  margin-left: -2px;
}
.imgUpload {
  display: flex;
  justify-content: center;
}
.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}
.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}
.level {
  padding: 3px;
  border-radius: 6px;
  color: #333;
  background-color: #e0f2ff;
  min-width: 20px;
  text-align: center;
}
</style>
